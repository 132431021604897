@charset "UTF-8";

@font-face {
  font-family: "pirates-icons";
  src:url("fonts/pirates-icons.eot");
  src:url("fonts/pirates-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/pirates-icons.woff") format("woff"),
    url("fonts/pirates-icons.ttf") format("truetype"),
    url("fonts/pirates-icons.svg#pirates-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "pirates-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="pnft-"]:before,
[class*=" pnft-"]:before {
  font-family: "pirates-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pnft-check:before {
  content: "\61";
}
.pnft-arrow-right:before {
  content: "\62";
}
.pnft-arrow-left:before {
  content: "\63";
}
.pnft-arrow-down:before {
  content: "\64";
}
.pnft-opensea:before {
  content: "\68";
}
.pnft-solana:before {
  content: "\6a";
}
.pnft-notification-on:before {
  content: "\6b";
}
.pnft-discord:before {
  content: "\6c";
}
.pnft-twitter:before {
  content: "\6d";
}
.pnft-opensea-sphere:before {
  content: "\6f";
}
.pnft-flow:before {
  content: "\70";
}
.pnft-angle-down:before {
  content: "\65";
}
.pnft-angle-left:before {
  content: "\66";
}
.pnft-angle-right:before {
  content: "\67";
}
.pnft-angle-up:before {
  content: "\69";
}
.pnft-solana-logo:before {
  content: "\6e";
}
.pnft-chevron-up:before {
  content: "\72";
}
.pnft-skull:before {
  content: "\73";
}
.pnft-heart:before {
  content: "\75";
}
.pnft-cancel:before {
  content: "\76";
}
.pnft-dot-3:before {
  content: "\78";
}
.pnft-upload-cloud:before {
  content: "\79";
}
.pnft-bars:before {
  content: "\7a";
}
.pnft-sphere-close:before {
  content: "\74";
}
.pnft-times-circle-o:before {
  content: "\77";
}
.pnft-cancel-circled:before {
  content: "\41";
}
.pnft-cw:before {
  content: "\42";
}
.pnft-eth:before {
  content: "\71";
}
.pnft-edit:before {
  content: "\43";
}
.pnft-refresh:before {
  content: "\44";
}
.pnft-clone:before {
  content: "\45";
}
.pnft-info:before {
  content: "\46";
}
.pnft-instagram-v2:before {
  content: "\47";
}
.pnft-looksrare:before {
  content: "\48";
}
.pnft-down-circled:before {
  content: "\49";
}
.pnft-gaia:before {
  content: "\4a";
}
.pnft-marketplace:before {
  content: "\4d";
}
.pnft-power:before {
  content: "\4c";
}
.pnft-rank:before {
  content: "\4b";
}
.pnft-power-unit:before {
  content: "\4e";
}
